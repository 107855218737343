
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { getObjectValueByPath } from '@/utils/vuetify/helpers'
  import { fixPrice } from '@/utils/general'

@Component({ methods: { fixPrice } })
  export default class Chips extends Vue {
  @Prop({ type: Array, required: true }) chips!: Array<any>;
  @Prop({ type: Object, default: () => ({ color: 'primary', textColor: 'white' }) }) config!: any;
  @Prop({ type: String }) itemText?: string;
  @Prop({ type: String }) formatter?: string;

  format (value) {
    const { formatter } = this
    if (!formatter) return value

    const format = this[formatter] as Function
    if (!format || typeof format !== 'function') throw new Error(`Unimplemented formatter ${formatter}`)

    return format(value)
  }

  asText (chip: any) {
    const { itemText } = this
    const text = itemText ? getObjectValueByPath(chip, itemText) : chip

    return this.format(text)
  }
  }
